<template>
  <div>
    <!-- <pre>{{ activeLiff }}</pre> -->
    <router-view />
  </div>
</template>

<script>
// import {
//   BTable,
// } from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import liff from '@line/liff'
import store from '@/store'

import dataStoreModule from './reserveStoreModule'

export default {
  components: {
    // BTable,
  },
  setup(_, { root }) {
    const APP_STORE_MODULE_NAME = 'app-v2-reserve'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // // Data func
    // const reserveServices = computed(() => store.state.saleOrder.reserve_services)
    // if (reserveServices.value.length === 0) {
    //   root.$router.push({ name: 'apps-reserve-v2-page', params: { slug: root.$route.params.slug, code: root.$route.params.code } })
    // }
    const activeLiff = ref(null)
    store.dispatch(`${APP_STORE_MODULE_NAME}/getLineLiff`, {
      slug: root.$route.params.slug,
    })
      .then(response => {
        const { liffId } = response.data
        // 開始 Liff 登入
        if (liffId) {
          liff.init({ liffId })
            .then(() => {
              // 沒有登入 LINE 轉導至登入頁
              // if (!liff.isLoggedIn()) {
              //   liff.login({ redirectUri: window.location })
              //   return
              // }

              const context = liff.getContext()
              activeLiff.value = context

              liff.getProfile()
                .then(profile => {
                  activeLiff.value = profile
                  store.state.saleOrder.line_uuid = profile.userId
                })
              // if (context && context.userId) {

              // }
            })
            .catch(error => {
              activeLiff.value = error.message
              // console.log(err.code, err.message)
            })
        }
        // 結束 Liff 登入
      })

    return {
      activeLiff,
    }
  },
}
</script>
